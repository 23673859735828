import React, { FC } from "react"

import "./styles.scss"

interface Props {}

const Layout: FC<Props> = ({ children }) => {
  return (
    <div className={"layout"}>
      <main>{children}</main>
    </div>
  )
}

export default Layout
